<template>
  <teleport to="body">
    <div v-if="open" class="modal" @click.self.prevent="open = ! open">
      <div class="wrapper">

        <div class="content">
          {{ message }}
        </div>

        <div class="actions">
          <button class="action danger" @click.prevent.stop="commit">{{ primary }}</button>
          <button class="action cancel" @click.prevent.stop="cancel">Cancel</button>
        </div>

      </div>

    </div>
  </teleport>
</template>

<script>

export default {
  name: 'Warning',
  props: ['show', 'message', 'primary'],
  emits: ['save'],
  data() {
    return {
      open: false
    }
  },
  methods: {
    cancel() {
      this.open = ! this.open;
    },
    commit() {
      this.$emit('save');
    },
  },
  watch: {
    show() {
      this.open = true;
    }
  }
}

</script>

<style lang="scss" scoped>

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  .wrapper {
    background-color: var(--primary-colour);
    padding: 1rem;
  }

  div.content {
    display: flex;
    flex-direction: column;
    min-height: 4rem;
    max-height: 30rem;
    max-width: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    color:var(--text-colour);
    h3.title {
      margin:0px 0px 1rem 0px;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ccc;
    }

    label { font-weight: bold; margin-bottom: 0.25rem;}
    input[type=text], input[type=number], textarea, input[type=checkbox], input[type=file] {
      padding: 0.5rem;
      font-size: 1rem;
      margin-top: 0rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      &:focus { outline: 0; }
    }

    textarea { height: 4rem; }

    img {
      display: block;
      width: 200px;
    }

  }

  div.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    //background-color: var(--warning-colour);
    margin: 0px;
    margin-top: 1rem;
    padding: 0px;
    border: none;
    button.action {
      margin: 0px;
      margin-right: 1rem;
      flex: 1 1;

      &:last-child { margin-right: 0px;}
      &.danger { margin-right: 20rem; width: 2rem; }
    }
  }
}

</style>
